import React from "react";
import "../assets/banner.scss"
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import CustomNav from "./navbar/CustomNav";

import AHAPLogo from "../assets/img/AHAPLogo.png";

// Core Components

function Header4() {
  return (
    <>
      <div class="banner">
        <CustomNav/>
        <div style={{}} class="content">
            <h1>As Healthy As Possible</h1>
            <p> A Non-Profit Focused On POLR2A </p>
        </div>
    </div>
    </>
  );
}

export default Header4;
