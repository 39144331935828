import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,

  Card,
  CardBody,
  CardTitle,

} from "reactstrap";
import _ from "lodash";
import articleBlue from "../assets/img/articledblue.png";
import articleGreen from "../assets/img/articlegreen.png";
import articleYellow from "../assets/img/articleyellow.png";


const ResearchArticle = ({ articleName, publishDate, url, researchArticleTypeId }) => {
  // Create a mapping of articleTypeId to image source
  const imageSrcMap = {
    1: articleBlue,
    2: articleGreen,
    3: articleYellow,
  };
  // Determine the image source based on articleTypeId
  const imageSrc = imageSrcMap[researchArticleTypeId] || 'default.jpg';
  
  return (
    <div style={{ margin: '.6em' }}>
      <Row>
        <Col className="mx-auto" lg="10" md="8">
          <Card className="card-blog card-plain" style={{padding:"20px"}}>
            <Row style={{display:"flex", justifyContent:"Center"}}>
              <Col xs="8" lg="3">
                <div className="card-image">
                  <img alt="..." className="img rounded" src={imageSrc}></img>
                  {console.log(imageSrc)}
                </div>
              </Col>
              <Col lg="9">
                <CardBody>
                  <CardTitle tag="h6">
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {articleName}
                    </a>
                  </CardTitle>
                  {publishDate !== "" ? <p>Published: {publishDate}</p> : "" }
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const ResearchArticleList = ({ articles, desiredArticleTypeId }) => {
  // Filter the articles to include only those with the desired articleTypeId
  const filteredArticles = articles.filter(article => article.researchArticleTypeId === desiredArticleTypeId);

  return (
    <div>
      {filteredArticles.map((article, index) => (
        <ResearchArticle
          key={index}
          articleName={article.name}
          publishDate={article.publishDate ? article.publishDate : ""}
          url={article.url}
          researchArticleTypeId={article.researchArticleTypeId}
        />
      ))}

    </div>
  );
};

export default ResearchArticleList;