import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import { UserContext, storage, constants, helpers } from "../utils";
import research from "../assets/img/research.jpg";
import together from "../assets/img/together.jpg";
import educating from "../assets/img/educating.jpg";
import ContactUs from "./ContactUs";
import Header4 from "./Header4";

export default function Home(props) {
  const userCtx = useContext(UserContext);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const columnsData = [
    {
      imageSrc: research,
      text: "It is our goal to make sure research that benefits those with a POLR2A gene mutation is continually visible and a driving force to academic institutions.",
      header: "Research",
    },
    {
      imageSrc: educating,
      text: "We endeavor to share and make the most information available to doctors, caregivers, and those interested in learning more about the POLR2A gene.",
      header: "Educate",
    },
    {
      imageSrc: together,
      text: "We are ambitious about being able to reach out to you, the people affected by mutations of the POLR2A gene, and be able to help you find the information and resources you need.",
      header: "Help Affected Families",
    },
  ];

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div style={{ justifyContent: "center", textAlign: "center" }}>
      <Header4/>
      <Row style={{margin:"0px", justifyContent:"center"}}>
        
          {columnsData.map((data, index) => (
            <Col key={index} style={{ margin:"10px"}}md={3}>
              <div className="column">
                <p className="ThreeColHeader">{data.header}</p>
                <img
                style={{borderRadius:15}}
                  src={data.imageSrc}
                  alt="Column"
                  className="column-image"
                />
                <p className="ThreeColText">{data.text}</p>
              </div>
            </Col>
          ))}
      </Row>
      <Row style={{ display:"flex",justifyContent:"center", width: "100%", paddingLeft: "50px", paddingRight: "50px", backgroundColor: "#0a2463"}}>
        <Col xs="12"><p style={{color:"white"}} className="ThreeColHeader">Contact</p></Col>
        <Col style={{borderRadius:15,backgroundColor:"white", marginTop:"20px",marginBottom:"20px"}} xs="12" md="6">
        
          <ContactUs/>
        </Col>
      </Row>
    </div>
  );
}
