import React, {useState} from 'react';
import "../../assets/banner.scss"
import "./navbar.css"
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from 'react-router-dom';
import {RiMenu3Line,RiCloseLine} from 'react-icons/ri'


const Menu = () => (
  <>
  <p className="whiteNav" style={{color:"white"}}><Link to="/">Home</Link></p>
  <p className="whiteNav" style={{color:"white"}}><Link to="/research">Research</Link></p>
  <p className="whiteNav" style={{color:"white"}}><Link to="/resources">Resources</Link></p>
  </>
)

const openPaypalTab = () => {
  const paypalURL = 'https://www.paypal.com/donate/?hosted_button_id=ES6D4RAJ639XW';
  window.open(paypalURL, '_blank');
};

function CustomNav() {
  const [toggleMenu, setToggleMenu] = useState(false)
  return (
    <div className = 'jpt3__navbar'>
      <div className='jpt3__navbar-links'>
        <div className='jpt3__navbar-links_logo'>
          {/* <img src={logo} alt="logo"/> */}
        </div>
        <div className='jpt3__navbar-links_container'>
          <Menu/>
        </div>
      </div>
      <div className='jpt3__navbar-sign'>
    <button onClick={openPaypalTab} ontype='button'>Donate</button>
      </div>
      <div className='jpt3__navbar-menu'>
        {toggleMenu 
          ? <RiCloseLine color="fff" size={27} onClick={()=>setToggleMenu(false)}/> 
          : <RiMenu3Line color="fff" size={27} onClick={()=>setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div style={{background:"white", zIndex:5}} className='jpt3__navbar-menu_container scale-up-center'>
            <div className='jpt3__navbar-menu_container-links'>
            <Menu/>
            <div className='jpt3__navbar-menu_container-links-sign'>
            <button onClick={openPaypalTab} ontype='button'>Donate</button>
            </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomNav;