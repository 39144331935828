import React, { useState, useContext, useEffect, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import _ from "lodash";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { api, constants, helpers, UserContext, storage } from "./utils";
import { Footer } from "./layouts";
import {
  Admin,
  Home,
  UserProfile,
  SignIn,
  User,
  Users,
  ResearchAdmin,
  ReferenceDataList,
  Research,
  Resources,
  UpdatePassword,
  HowToHelp,
} from "./components";
import dotenv from 'dotenv';
import Article from "./components/Article";

const nowTime = () => new Date().getTime();
const { THEMES } = constants;

dotenv.config();

export default function App() {
  const userCtx = useContext(UserContext);
  const tokenFromStorage = api.userToken() || null;
  const parsedUser = tokenFromStorage
    ? JSON.parse(storage.getItem("currentUser"))
    : null;
  const [authToken, setAuthToken] = useState(tokenFromStorage);
  const [currentUser, setCurrentUser] = useState(parsedUser);
  const [impersonating, setImpersonating] = useState();
  const [theme, setTheme] = useState(THEMES.default);
  const [alertMessage, setAlertMessage] = useState(null);
  const [onPublicPage, setOnPublicPage] = useState(true);
  const navigate = useNavigate();

  // function isAuthenticated() {
  //   return authToken !== null;
  // }

  // function updateUserContext(userRole) {
  //   api
  //     .post("UserAdmin/UpdateCurrentUserContext", {
  //       UserRole: userRole,
  //       User: currentUser,
  //     })
  //     .then((r) => {
  //       if (r?.data?.user) {
  //         let newRoleUser = r.data.user;
  //         storage.setItem("currentUser", JSON.stringify(newRoleUser));
  //         setCurrentUser(newRoleUser);
  //         navigate("/admin/menu");
  //       }
  //     });
  // }

  // function enhanceUser(u) {
  //   if (!u) return u;
  //   // u.includeHaulerFirmModule = u.isHaulerAdminUser;
  //   if (u.token) {
  //     delete u.token;
  //   }
  //   return u;
  // }

  // function signOut(message) {
  //   if (!impersonating) {
  //     storage.removeItem("token");
  //     storage.removeItem("currentUser");
  //     storage.removeItem("lastActivity");
  //     storage.removeItem("sessionStartedAt");
  //     setCurrentUser(null);
  //     setAuthToken(null);
  //     if (message) {
  //       Alert.success(message);
  //     }
  //     navigate("/login");
  //   } else {
  //     clearImpersonation();
  //     navigate("/");
  //   }
  // }

  // function clearImpersonation() {
  //   if (!impersonating) {
  //     return; // do not allow clear of the primary user if we're not impersonating
  //   }
  //   // get the original admin user
  //   const adminUser = JSON.parse(storage.getItem("adminUser"));
  //   const adminUserToken = storage.getItem("adminToken");
  //   // clear localstorage
  //   storage.removeItem("adminToken");
  //   storage.removeItem("adminUser");
  //   // set the admin user back as primary
  //   storage.setItem("token", adminUserToken);
  //   storage.setItem("currentUser", JSON.stringify(adminUser));
  //   storage.setItem("untethering", true);
  //   setCurrentUser(adminUser);
  //   setAuthToken(adminUserToken);
  //   setImpersonating(false);
  // }

  // function impersonate(user, impersonateToken) {
  //   if (impersonating) {
  //     return; // do not allow impersonation a 2nd time if we're already in that mode
  //   }
  //   // remember the admin user's details
  //   const adminUser = JSON.parse(storage.getItem("currentUser"));
  //   const adminUserToken = storage.getItem("token");

  //   // clear localstorage
  //   storage.removeItem("token");
  //   storage.removeItem("currentUser");
  //   // set the impersonation details
  //   user = enhanceUser(user);
  //   storage.setItem("token", impersonateToken);
  //   storage.setItem("currentUser", JSON.stringify(user));
  //   // remember the admin details for later
  //   storage.setItem("adminToken", adminUserToken);
  //   storage.setItem("adminUser", JSON.stringify(adminUser));
  //   setCurrentUser(user);
  //   setAuthToken(impersonateToken);
  //   setImpersonating(true);
  //   navigate("/dashboard");
  // }

  // const AuthRoute = ({ component: Component, ...extraProps }) => {
  //   return (
  //     <Route
  //       {...extraProps}
  //       render={(props) => {
  //         setOnPublicPage(false);
  //         const combinedProps = Object.assign(props, extraProps);
  //         if (!authToken) {
  //           setOnPublicPage(true);
  //           return <Redirect to="/" />;
  //         }
  //         if (!isAuthenticated()) {
  //           setOnPublicPage(true);
  //           return <Redirect to="/" />;
  //         }
  //         return storage.getItem("untethering") ? (
  //           unSetTetheringAndRedirect(extraProps.location.pathname)
  //         ) : (
  //           <Component {...combinedProps} />
  //         );
  //       }}
  //     />
  //   );
  // };

  // const LoginRoute = ({ component: Component, ...extraProps }) => {
  //   return (
  //     <Route
  //       {...extraProps}
  //       render={(props) => {
  //         const combinedProps = Object.assign(props, extraProps);
  //         if (isAuthenticated()) {
  //           setOnPublicPage(false);
  //           return <Redirect to="/admin/menu" />;
  //         }
  //         if (
  //           (_.startsWith(combinedProps.path, "/reset_password") ||
  //             _.startsWith(combinedProps.path, "/forgot_password")) &&
  //           Component
  //         ) {
  //           setOnPublicPage(true);
  //           return <Component {...combinedProps} />;
  //         }
  //         setOnPublicPage(true);
  //         return <SignIn {...combinedProps} />;
  //       }}
  //     />
  //   );
  // };

  function unSetTetheringAndRedirect(path) {
    storage.removeItem("untethering");
    navigate("/");
  }
  const referencePathList = _.map(
    constants.REFERENCE_DATA_URL_LIST,
    (x) => x.reactPath
  );
  const showSidebar = userCtx && currentUser && currentUser.id;

  // const user = {
  //   currentUser: currentUser,
  //   setCurrentUserContext: updateUserContext,
  //   theme: theme,
  //   setTheme: setTheme,
  //   impersonate: impersonate,
  //   clearImpersonation: clearImpersonation,
  //   signIn: (newUser, token) => {
  //     if (token) {
  //       storage.setItem("token", token);
  //       setAuthToken(token);
  //     }
  //     newUser = enhanceUser(newUser);
  //     storage.setItem("lastUsername", newUser.username);
  //     storage.setItem("currentUser", JSON.stringify(newUser));
  //     storage.setItem("sessionStartedAt", nowTime());
  //     setCurrentUser(newUser);
  //     setAlertMessage(null);
  //   },
  //   signOut: signOut,
  // };

  return (
    <div className={`${theme} siteContainer fullHeight`}>
      <Alert
        effect="slide"
        position="top-right"
        stack={{ limit: 1 }}
        timeout={4000}
        html={true}
        offset={1}
        z-index={4000}
        preserveContext
      />
        <div>
            <Fragment>
              {helpers.mustChangePassword(currentUser) ? (
                <Routes>
                  <Route exact path="/*" element={<UpdatePassword />} />
                </Routes>
              ) : (
                <Routes>
                  <Route exact path="/home" element={<Home />} />
                  <Route exact path="/user/:id" element={<User />} />
                  <Route exact path="/article/:id" element={<Article />} />
                  <Route exact path="/admin/users" element={<Users />} />
                  <Route exact path="/admin/research" element={<ResearchAdmin />} />
                  <Route exact path="/admin/:tabName" element={<Admin />} />
                  <Route exact path="/admin/menu" element={<Admin />} />
                  <Route exact path="/profile" element={<UserProfile />} />
                  <Route exact path="/research" element={<Research />} />
                  <Route exact path="/resources" element={<Resources />} />
                  <Route exact path="/howtohelp" element={<HowToHelp />} />
                  <Route exact path="/" element={<Home />} />
                </Routes>
              )}
            </Fragment>
          
        </div>
        <Footer />
    </div>
  );
  // return (
  //   <div className={`${theme} siteContainer fullHeight`}>
  //     <Alert
  //       effect="slide"
  //       position="top-right"
  //       stack={{ limit: 1 }}
  //       timeout={4000}
  //       html={true}
  //       offset={1}
  //       z-index={4000}
  //       preserveContext
  //     />
  //     <UserContext.Provider value={user}>
  //       <div>
  //         {isAuthenticated() ? (
  //           // all authorized routes
  //           <Fragment>
  //             {helpers.mustChangePassword(currentUser) ? (
  //               <Routes>
  //                 <Route exact path="/*" element={<UpdatePassword />} />
  //               </Routes>
  //             ) : (
  //               <Routes>
  //                 <Route exact path="/home" element={<Home />} />
  //                 <Route exact path="/user/:id" element={<User />} />
  //                 <Route exact path="/article/:id" element={<Article />} />
  //                 <Route exact path="/admin/users" element={<Users />} />
  //                 <Route exact path="/admin/research" element={<ResearchAdmin />} />
  //                 <Route exact path="/admin/:tabName" element={<Admin />} />
  //                 <Route exact path="/admin/menu" element={<Admin />} />
  //                 <Route exact path="/profile" element={<UserProfile />} />
  //                 <Route exact path="/research" element={<Research />} />
  //                 <Route exact path="/howtohelp" element={<HowToHelp />} />
  //                 <Route exact path="/" element={<Home />} />
  //               </Routes>
  //             )}
  //           </Fragment>
  //         ) : (
  //           // all unauthorized routes
  //           <Routes>
  //             <Route exact path="/login" element={<SignIn />} />
  //             <Route path="/forgot_password" element={<ForgotPassword />} />
  //             <Route
  //               path="/reset_password/:resetToken"
  //               element={<ResetPassword />}
  //             />
  //             <Route exact path="/" element={<SignIn />} />
  //             <Route exact path="/*" element={<SignIn />} />

  //             {/* <Route exact path={referencePathList} element={<AuthRoute
  //             exact
  //             path={referencePathList}
  //             component={ReferenceDataList}
  //           />} /> */}
  //           </Routes>
  //         )}
  //       </div>
  //       <Footer />
  //     </UserContext.Provider>
  //   </div>
  // );
}
