import React, { Fragment, useState, useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import Alert from "react-s-alert";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
// import DebounceInput from "react-debounce-input";
import { Col, Button, Row, Table, ButtonGroup, Modal, ModalHeader, ModalBody, Card, CardBody, Label, Input, ModalFooter, Alert as Alert2 } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import classnames from "classnames";
import { api, helpers, UserContext, filterHelpers, constants } from "../utils";
import {
  FilterText,
  FilterableScreen,
  FilterSet,
  FilterSwitch,
  // FilterSelect,
  FilterMultiSelect,
  Pager
} from ".";
import { DesktopContainer } from "../layouts";


export default function ResearchAdmin(props) {
  const userCtx = useContext(UserContext);
  const [filters, setFilters] = useState([{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only"}]);
  const [paginatedList, setPaginatedList] = useState({list: [], pageNumber: 1, totalPages: 1});
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [message, setMessage] = useState(null);
  // const [tenants, setTenants] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [textBody, setTextBody] = useState("");
  const [toggleSelectAll, setToggleSelectAll] = useState(false);
  const [selectedArticleIds, setSelectedArticleIds] = useState([]);
  const [massNotifyPrompt, setMassNotifyPrompt] = useState('');

  // useEffect(() => {
  //   let apiCalls = [];
  //   apiCalls.push(api.getTenants());
  //   Promise.all(apiCalls).then(arrayResults => {
  //     let aggResults = {};
  //     _.each(arrayResults, x => Object.assign(aggResults, x));
  //     setTenants(aggResults.tenants);
  //   }).catch(error => setMessage({flavor: "alert-danger", text: "There was an error loading user data"}));
  // }, [])

  useEffect(() => {
    refreshData();
  }, [filters, pageNumber])

  function createPayload() {
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      pageNumber: pageNumber
    };
    _.each(filters, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData() {
    api.post('ResearchAdmin/List', createPayload()).then((response) => {
      setSelectedArticleIds([]);
      setPaginatedList(response.data);
      {console.log(response.data)}
    }).catch(helpers.catchHandler);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }


  function onToggleActive(selected) {
    api.post(`ResearchAdmin/ToggleResearchArticleActive/${selected.id}`).then(response => {
      if (response.data.success) {
        refreshData();
      } else {
        setMessage({ flavor: "danger", text: response.data.message });
      }
    }).catch(helpers.catchHandler);
  }

  function onToggleSelectAll(on) {
    console.log('toggle all ' + on)
    setToggleSelectAll(on);
    setSelectedArticleIds(on ? _.map(paginatedList.list, x => x.id) : []);
  } 

  return (
  <DesktopContainer screenName={"Articles"}>
    <FilterableScreen
      filters={
        <Row>
          <Col xs="10">
            <FilterSet
              filters={filters}
              clearFilters={() => setFilters([])}
            >
              <Row>
                <Col xs="3">
                  <FilterText
                    filterName="Name"
                    displayName="Name"
                    value={filterHelpers.currentFilterValue(filters, "Name")}
                    onChangeCallback={(e) => onFilterChange(e)}
                  />
                </Col>
                <Col xs="3">
                  <FilterSwitch
                    filterName="ActiveOnly"
                    displayName="Active Only"
                    value={filterHelpers.currentFilterValue(filters, "ActiveOnly")}
                    onChangeCallback={(e) => onFilterChange(e)}
                  />
                </Col>
                <Col xs="3">
                  <FilterMultiSelect
                    filterName="TypeOfArticle"
                    displayName="Article"
                    values={filterHelpers.currentFilterValue(filters, 'TypeOfArticle')}
                    onChangeCallback={(e) => onFilterChange(e)}
                    options={constants.ALL_ARTICLE_TYPES}
                  />
                </Col>
              </Row>
            </FilterSet>
          </Col>
        </Row>
      }
      pager={(<div className="float-right">
          <Pager 
            pageNumber={paginatedList?.pageNumber ? paginatedList.pageNumber : 0}
            totalPages={paginatedList?.totalPages ? paginatedList.totalPages : 0}
            callBack={(newPageNumber) => setPageNumber(newPageNumber)} 
          />
        </div>)}
    >
    <Card>
    <CardBody>
      <Row className="p-0 m-0">
        <Col xs="12">
          {message
            ? (<Alert2 className={message.flavor}>{message.text}</Alert2>)
            : null
          }
          <Table
            striped
            hover
            size="sm"
            responsive={false}
            id="userTable"
          >
            <thead>
              <tr>
                <th width="40px">
                  <input type="checkbox" checked={toggleSelectAll} onChange={(e) => onToggleSelectAll(e.target.checked)} />
                </th>
                <th width="40px"></th>
                <th>Name</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_.map(paginatedList.list, article => (
                <tr key={`a-${article.id}`}>
                  <td width="40px">
                    <input type="checkbox"
                      checked={selectedArticleIds.includes(article.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let newIdList = selectedArticleIds.slice();
                          newIdList.push(article.id);
                          setSelectedArticleIds(newIdList);
                        } else {
                          setSelectedArticleIds(_.reject(selectedArticleIds, x => x === article.id));
                        }
                      }} />
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    <Link to={`/research/${article.id}`}>
                    <td style={{fontSize:"15px"}}>{/*u.email*/}{article.name}</td>
                    </Link>
                  </td>
                 
                  <td style={{maxWidth:"100px", overflow:"hidden", textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{article.articleTypeId}</td>
                  
                  <td>
                    <ButtonGroup className="float-right">
                      {article.deactivatedAt ? (
                        <Button
                          size="sm"
                          className="btn-light"
                          title="Revive"
                          onClick={() => onToggleActive(article)}
                        >
                          <FontAwesomeIcon icon='recycle' />
                        </Button>
                      ) : (
                        <Fragment>
                          <Button
                            size="sm"
                            className="projectWarning"
                            title="Deactivate"
                            onClick={() => onToggleActive(article)}
                          >
                            <FontAwesomeIcon icon='times-circle' />
                          </Button>
     
                        </Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {massNotifyPrompt ? (
        <Modal isOpen={true} toggle={() => setMassNotifyPrompt("")}>
          <ModalHeader className="primary">
            <FontAwesomeIcon icon="mail-bulk" /> {massNotifyPrompt}
          </ModalHeader>
          <ModalBody>
            {/* <div className="bg-warning p-3 mb-2">
              If a user has provided a mobile phone # it will be used preferentially. Email will be used otherwise.
            </div> */}
            <Label>Type your message here:</Label>
            <Input
              type="textarea"
              value={textBody}
              onChange={e => setTextBody(e.target.value)}
            />
          </ModalBody>
        </Modal>
      //   )
      //   : null
      // }
      // {textPhoneNumber ? (
      //   <Modal isOpen={true} toggle={() => setTextPhoneNumber(null)}>
      //     <ModalHeader className="primary">
      //       <FontAwesomeIcon icon="mail-bulk" /> Send custom text to {textFullName}
      //     </ModalHeader>
      //     <ModalBody>
      //       <Label>Type your message here:</Label>
      //       <Input
      //         type="textarea"
      //         value={textBody}
      //         onChange={e => setTextBody(e.target.value)}
      //       />
      //     </ModalBody>
      //     <ModalFooter className="float-right">
      //       <ButtonGroup>
      //         <Button size="sm" color="primary" onClick={sendText}>
      //           Send
      //         </Button>
      //         <Button
      //           size="sm"
      //           color="secondary"
      //           onClick={() => setTextPhoneNumber(null)}
      //         >
      //           Cancel
      //         </Button>
      //       </ButtonGroup>
      //     </ModalFooter>
      //   </Modal>
      ) : null}
    </CardBody>
    </Card>
    </FilterableScreen>
  </DesktopContainer>);
}