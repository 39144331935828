import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import { UserContext, storage, constants, helpers, api } from "../utils";
import CustomNav from "./navbar/CustomNav";
import ResearchArticleList from "./ResearchArticleList";
import research from "../assets/img/research.jpg";
import articleBlue from "../assets/img/articledblue.png";
import articleGreen from "../assets/img/articlegreen.png";
import articleYellow from "../assets/img/articleyellow.png";
import Header3 from "./Header3";

export default function Blogs3() {
  const userCtx = useContext(UserContext);
  const [filters, setFilters] = useState([{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only"}]);
  const [paginatedList, setPaginatedList] = useState({list: [], pageNumber: 1, totalPages: 1});
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [message, setMessage] = useState(null);
  // const [tenants, setTenants] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [textBody, setTextBody] = useState("");
  const [toggleSelectAll, setToggleSelectAll] = useState(false);
  const [selectedArticleIds, setSelectedArticleIds] = useState([]);
  const [articleList, setArticleList] = useState([
    {
        "id": 1,
        "researchArticleTypeId": 1,
        "createdAt": "2023-10-18T10:31:41.977",
        "name": "De Novo Heterozygous POLR2A Variants Cause a Neurodevelopmental Syndrome with Profound Infantile-Onset Hypotonia",
        "url": "https://pubmed.ncbi.nlm.nih.gov/31353023/",
        "publishDate": "2019 Aug 1",
        "deactivatedAt": null
    },
    {
        "id": 2,
        "researchArticleTypeId": 1,
        "createdAt": "2023-10-18T10:31:41.977",
        "name": "Germline mutation in POLR2A: a heterogeneous, multi-systemic developmental disorder characterized by transcriptional dysregulation",
        "url": "https://pubmed.ncbi.nlm.nih.gov/33665635/",
        "publishDate": "2021 Jan 14",
        "deactivatedAt": null
    },
    {
        "id": 3,
        "researchArticleTypeId": 1,
        "createdAt": "2023-10-18T10:31:41.977",
        "name": "Complex Autism Spectrum Disorder with Epilepsy, Strabismus and Self-Injurious Behaviors in a Patient with a De Novo Heterozygous POLR2A Variant",
        "url": "https://pubmed.ncbi.nlm.nih.gov/35328024/",
        "publishDate": "2022 Mar 7",
        "deactivatedAt": null
    },
    {
        "id": 4,
        "researchArticleTypeId": 1,
        "createdAt": "2023-10-18T10:31:41.977",
        "name": "De novo POLR2A p.(Ile457Thr) variant associated with early-onset encephalopathy and cerebellar atrophy: expanding the phenotypic spectrum",
        "url": "www.google.com",
        "publishDate": "2022 Aug",
        "deactivatedAt": null
    },
    {
        "id": 5,
        "researchArticleTypeId": 2,
        "createdAt": "2019-08-01T00:00:01",
        "name": "POLR2A RNA polymerase II subunit A [ Homo sapiens (human) ]",
        "url": "https://www.ncbi.nlm.nih.gov/gene/5430",
        "publishDate": "2023 Oct",
        "deactivatedAt": null
    },
    {
        "id": 6,
        "researchArticleTypeId": 2,
        "createdAt": "2019-08-01T00:00:01",
        "name": "Nlorem",
        "url": "https://www.nlorem.org/",
        "deactivatedAt": null
    },
    {
        "id": 7,
        "researchArticleTypeId": 2,
        "createdAt": "2019-08-01T00:00:01",
        "name": "GARD - Genetic and Rare Diseases Information Center",
        "url": "https://rarediseases.info.nih.gov/",
        "deactivatedAt": null
    },
    {
      "id": 8,
      "researchArticleTypeId": 2,
      "createdAt": "2019-08-01T00:00:01",
      "name": "Rare Diseases Clinical Research Network",
      "url": "https://www.rarediseasesnetwork.org/",
      "deactivatedAt": null
  },
  {
    "id": 9,
    "researchArticleTypeId": 3,
    "createdAt": "2019-08-01T00:00:01",
    "name": "Polr2a Wikipedia",
    "url": "https://en.wikipedia.org/wiki/POLR2A",
    "deactivatedAt": null
},
{
  "id": 10,
  "researchArticleTypeId": 3,
  "createdAt": "2019-08-01T00:00:01",
  "name": "NA Transcription (Basic) VIDEO",
  "url": "https://www.youtube.com/watch?v=5MfSYnItYvg&ab_channel=DNALearningCenter",
  "publishDate": "2010 Mar",
  "deactivatedAt": null
},
{
  "id": 11,
  "researchArticleTypeId": 9,
  "createdAt": "2019-08-01T00:00:01",
  "name": "DNA animation (2002-2014) by Drew Berry and Etsuko Uno wehi.tv",
  "url": "https://www.youtube.com/watch?v=7Hk9jct2ozY&ab_channel=WEHImovies",
  "publishDate": "2019 Jul",
  "deactivatedAt": null
}
]);
  const [massNotifyPrompt, setMassNotifyPrompt] = useState('');
  
  useEffect(() => {
    // refreshData();
  }, [filters, pageNumber])

  function createPayload() {
    let payload = {
    };
    _.each(filters, filter => payload[filter.filterName] = filter.value);
    return payload;
  }
  // function refreshData() {
  //   api.post('ResearchAdmin/List', createPayload()).then((response) => {
  //     setSelectedArticleIds([]);
  //     setPaginatedList(response.data);
  //   }).catch(helpers.catchHandler);
  // }
  
 
  
  return (
    <div style={{backgroundColor:"#191f4d"}}>
      <Header3/>
      <div style={{height:"1rem", backgroundColor:"white"}}></div>
      <div style={{padding:"2em", textAlign:"center"}}className="tests">
        <div style={{paddingBottom:"2em"}} className="justify-content-center">
          <h2 style={{color:"white"}}>Research Papers</h2>
        </div>
        <section className="blogs-3">
          <Container style={{textAlign:"start"}}>
            <ResearchArticleList articles={articleList} desiredArticleTypeId={1}/>
          </Container>
        </section>
      </div>
      <div style={{padding:"2em"}}>
        <div style={{paddingBottom:"2em", textAlign:"center"}} className="justify-content-center">
          <h2 style={{color:"white"}}>Rare Disease Resources</h2>
        </div>
          <section  className="blogs-3">
            <Container>
              <ResearchArticleList articles={articleList} desiredArticleTypeId={2}/>
            </Container>
          </section>
      </div>
      <div style={{padding:"2em", textAlign:"center"}} className="justify-content-center">
        <h2 style={{color:"white"}}>Additional Information About POLR2A</h2>
      </div>
        <section style={{paddingBottom:"20px"}} className="blogs-3">
          <Container>
           <ResearchArticleList articles={articleList} desiredArticleTypeId={3} />
          </Container>
        </section>
    </div>
  );
}