import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_KEY;
const templateKey = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY;
const userKey = process.env.REACT_APP_EMAILJS_USER_KEY;

const ContactUs = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(serviceKey, templateKey, form.current, userKey)
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setIsSuccess(true); // Set the success state to true
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isFormValid = formData.user_name && formData.user_email && formData.message;

  return (
    <div>
      {isSuccess ? ( // Render the success message when isSuccess is true
        <div>
          <p>Message sent successfully!</p>
          <button onClick={() => setIsSuccess(false)}>Close</button>
        </div>
      ) : (
        <form style={{ borderRadius: 15, fontWeight:500, fontSize:18}} ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" value={formData.user_name} onChange={handleInputChange} />
      <label>Email</label>
      <input type="email" name="user_email" value={formData.user_email} onChange={handleInputChange} />
      <label>Message</label>
      <textarea name="message" value={formData.message} onChange={handleInputChange} />
      <input
        style={{ marginBottom: "20px" }}
        type="submit"
        value="Send"
        className={isFormValid ? "send-button" : "send-button-disabled"}
        disabled={!isFormValid}
      />
    </form>
      )}
    </div>
  );
};

export default ContactUs;