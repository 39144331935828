import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Media } from "react-breakpoints";
import { Col, Button, Row, ButtonGroup, ListGroup, ListGroupItem, Card, CardTitle, CardHeader, CardBody, 
  Badge, Table, FormGroup, Label, Input, Alert } from "reactstrap";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Dropzone from 'react-dropzone';
import DebounceInput from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import _ from "lodash";
import Switch from "react-switch";
import { api, helpers, UserContext, constants } from "../utils";
import { DesktopContainer } from "../layouts";

const EMPTY_ARTICLE_TYPE = {
  typeOfArticle: null
};
const roleGroupStyles = {
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 'bold',
  textDecoration: 'underline',  
  margin: '2px 0 0 0'
};

const formatRoleGroupLabel = data => (
  <div style={roleGroupStyles}>
    <span>{data.label}</span>
  </div>
);

const defaultNewArticle = {
  id: 0,
  name: '',
  url: '',
  articleType: ''
};
  
export default function Article(props) {
  const userCtx = useContext(UserContext);
  const { id } = useParams();
  const [article, setArticle] = useState(defaultNewArticle);
  const [articleTypes, setArticleType] = useState(article.articleType || '');
  const [timezoneList, setTimezoneList] = useState([]);
  const [newArticleType, setNewArticleType] = useState("");
  const [message, setMessage] = useState(null);
  const [articleTypeSelectionState, setArticleTypeSelectionState] = useState(null);
  const navigate = useNavigate;
  const isNewArticle = !id || parseInt(id, 10) < 1;
  const currentUser = userCtx?.currentUser
      ? userCtx.currentUser
      : null;

      //getArticle
  const getArticle = () => api.fetch(`ResearchAdmin/Article/${id}`).then(r => ({
    article: r.data
  }));

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(api.getTimezones());
    if (parseInt(id, 10) > 0)
    {
      apiCalls.push(getArticle());
    }
    Promise.all(apiCalls).then(arrayResults => {
      let aggResults = {};
      _.each(arrayResults, x => Object.assign(aggResults, x));
      setTimezoneList(aggResults.timezones ? aggResults.timezones : []);
      const existingArticle = aggResults.article ? aggResults.article : null;
      if (existingArticle) {
        setArticle(existingArticle);
      }
    }).catch(error => setMessage({flavor: "alert-danger", text: "There was an error loading user data"}));
  }, [])

  function onChange(fieldName, fieldValue) {
    let newArticle = Object.assign({}, article);
    newArticle[fieldName] = fieldValue;
    setArticle(newArticle);
  }

  //onTypeRemove
  function onRemoveType(selectedArticleType) {
    let newArticle= Object.assign({}, article);
    newArticle.articleType = _.reject(article.articleType, r => r.typeOfArticle === selectedArticleType.typeOfUserRole )
    setArticle(newArticle);
  }

  //appendTypeWithContent
  function appendTypeWithContent(articleTypeList, newArticleType) {
    let builtNewArticleType = {
      ...EMPTY_ARTICLE_TYPE,
      typeOfArticle: newArticleType.typeSelection.value
    };
    // if (newArticleType.typeSelection.value === constants.ROLE_IDS.SYSADMIN) {
    //   articleTypeList.push(builtNewArticleType);
    //   return articleTypeList;
    // }
    articleTypeList.push(builtNewArticleType);
    return articleTypeList;
  }

  function freshArticleTypeState(typeSelection) {
    let nextArticleType = {
      typeSelection: typeSelection
    };
    return nextArticleType;
  }

  //onAddArticleType
  function onAddArticleType() {
    if (!articleTypeSelectionState?.newRole?.typeSelection) {
      setMessage({flavor: "projectWarning", text: "Select a role before attempting to add it to the list."});
      return;
    }
    // if (articleTypeSelectionState?.newRole?.typeSelection.value === constants.ROLE_IDS.SYSADMIN
    //   && _.some(article.articleType, ur => ur.typeOfArticle === constants.ROLE_IDS.SYSADMIN)) 
    // {
    //   setMessage({flavor: "projectWarning", text: 'The system administrator role has already been given.'});
    //   return;
    // }
    let duplicate = false;
    for(var j=0; j<article.articleType.length; j++) {
      const ur = article.articleType[j];
      if (ur.typeOfArticle === articleTypeSelectionState.newArticleType.typeSelection.value) {
        duplicate = true;
        break;
      }
    }
    if (duplicate) {
      setMessage({flavor: "projectWarning", text: 'This role has already been given.'});
      return;
    }
    let newArticle = Object.assign({}, article);
    newArticle.articleType = appendTypeWithContent(article.articleType.slice(), articleTypeSelectionState.newArticleType);
    setArticle(newArticle);
    setArticleTypeSelectionState({
      ...articleTypeSelectionState,
      newArticleType: freshArticleTypeState(articleTypeSelectionState.newRole.typeSelection)
    });
  }

  //onArticleTypeChange
  function onArticleTypeChange(fieldName, selection) {
    let newArticleTypeState = freshArticleTypeState(articleTypeSelectionState?.newArticleType?.typeSelection);
    newArticleTypeState[fieldName] = selection;
    setArticleTypeSelectionState({
      newArticleType: newArticleTypeState
    });
  }


  function onSave() {
    window.scroll(0, 0);
    
    let payload = Object.assign({}, article);
    payload.name = _.trim(payload.name);
    payload.url = _.trim(payload.url);
    payload.username = payload.email; // username is always set to email
    if (isNewArticle) { 
      delete payload.id; 
    }
    api.post('ResearchAdmin/SaveArticle', payload).then(response => {
      if (response.data.success) {
        setArticle({...article, id: response.data.message.id})
        navigate("/Articles");
      } else {
        setMessage({text: response.data.message, flavor: "alert-danger"});
      }
    }).catch(helpers.catchHandler);
  }

  const newSelectedArticleType = articleTypeSelectionState?.newArticleType?.typeSelection
    ? _.find(constants.roles, r => r.value === articleTypeSelectionState.newRole.typeSelection.value)
    : null;
  let newSelectedRoleContextDescription = null;
  if (!currentUser) return null;

  return (
  <DesktopContainer screenName={`User Details`}>
    {message
      ? (<Alert className={message.flavor}>{message.text}</Alert>)
      : null
    }
    <Row className="mb-3">
      <Col xs="12">
        <Card>
          <CardBody>
            <Row className="mb-1">
              <Col xs="3">Name {helpers.requiredStar()}
                <DebounceInput
                  type="text"
                  name="name"
                  id="name"
                  maxLength="50"
                  onChange={(event) => onChange(event.target.name, event.target.value)}
                  value={article.name || ""}
                  className="form-control"
                  debounceTimeout={300}
                />
              </Col>
              
              <Col xs="3">Email {helpers.requiredStar()}
                <DebounceInput
                  type="text" 
                  name="url"
                  id="url"
                  maxLength="1000"
                  onChange={(event) => onChange(event.target.name, event.target.value)}
                  value={article.email || ""}
                  className="form-control"
                  debounceTimeout={300}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col xs="12">
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h5>Adjust Article Type</h5>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="pt-3">
              <Col xs="2">
                <Label className="mb-0">Roles</Label>
                <Select
                  options={constants.ALL_ARTICLE_TYPES}
                  formatGroupLabel={formatRoleGroupLabel}
                  value={articleTypeSelectionState?.newArticleType?.typeSelection}
                  onChange={(option) => onArticleTypeChange('typeSelection', option)}
                />
                {articleTypeSelectionState && articleTypeSelectionState.newRole
                  ? (<div style={{margin: "4px", padding: "4px", backgroundColor: "#f2f5ff", borderRadius: "4px"}}>
                      <b>{articleTypeSelectionState.newRole.typeSelection.label}</b>{newSelectedRoleContextDescription}
                    </div>)
                  : null
                }                              
              </Col>
              <Col className="pt-4">
                <Button className="projectSuccess float-right" onClick={onAddArticleType} title="Add this role">
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                  {_.sortBy(article.articleType, r => constants.ALL_ARTICLE_TYPES[r.typeOfArticle]).map((r, index) => (
                    <tr key={`${r.typeOfArticle}-${index}`}
                    >
                      <td>{constants.ALL_ARTICLE_TYPES[r.typeOfArticle]}</td>
                      <td>{r.label}</td>
                      <td>
                        <Button onClick={() => onRemoveType(r)} className="projectDanger" size="sm" title="Remove">
                          <FontAwesomeIcon icon="times-circle" />
                        </Button>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="my-4">
      <Col xs="12">
        <Row>
          <Col className="text-right">
            <ButtonGroup>
              <Button
                color="secondary"
                tag={Link}
                to="/admin/users"
              >
                Cancel
              </Button>
              <Button
                className="projectPrimary"
                onClick={onSave}
              >
                Save
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  </DesktopContainer>
  );
}
