import React from "react";
import "../assets/banner.scss"
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import CustomNav from "./navbar/CustomNav";

import AHAPLogo from "../assets/img/AHAPLogo.png";

// Core Components

function Header2() {
  return (
    <>
      <div class="banner2">
        <CustomNav/>
        <div class="content2">
            <h1>Resources</h1>
        </div>
    </div>
    </>
  );
}

export default Header2;
