import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import { UserContext, storage, constants, helpers, api } from "../utils";
import CustomNav from "./navbar/CustomNav";
import ResearchArticleList from "./ResearchArticleList";
import research from "../assets/img/research.jpg";
import articleBlue from "../assets/img/articledblue.png";
import articleGreen from "../assets/img/articlegreen.png";
import articleYellow from "../assets/img/articleyellow.png";
import Header2 from "./Header2";

export default function Resources() {
  const userCtx = useContext(UserContext);
  const [filters, setFilters] = useState([{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only"}]);
  const [paginatedList, setPaginatedList] = useState({list: [], pageNumber: 1, totalPages: 1});
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [message, setMessage] = useState(null);
  // const [tenants, setTenants] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [textBody, setTextBody] = useState("");
  const [toggleSelectAll, setToggleSelectAll] = useState(false);
  const [selectedArticleIds, setSelectedArticleIds] = useState([]);

  
  useEffect(() => {
    // refreshData();
  }, [filters, pageNumber])

  function createPayload() {
    let payload = {
    };
    _.each(filters, filter => payload[filter.filterName] = filter.value);
    return payload;
  }
  // function refreshData() {
  //   api.post('ResearchAdmin/List', createPayload()).then((response) => {
  //     setSelectedArticleIds([]);
  //     setPaginatedList(response.data);
  //   }).catch(helpers.catchHandler);
  // }
  
 
  
  return (
    <div style={{backgroundColor:"#fff"}}>
      <Header2/>
      <div style={{height:"1rem", backgroundColor:"white"}}></div>
      <div style={{padding:"2em", textAlign:"center"}}className="tests">
        <div style={{paddingBottom:"2em"}} className="justify-content-center">
          <h2 style={{color:"#0a2463"}}>Therapy</h2>
          <Row style={{display:"flex",justifyContent:"center"}} ><Col xs="11" md="8"><p style={{textAlign:"start"}}>As Healthy As Possible is working to provide access to different therapies.  Check back soon for updates or please feel free to contact us at info@AsHealthyAsPossible.net</p></Col></Row>
        </div>
        <div style={{paddingBottom:"2em"}} className="justify-content-center">
          <h2 style={{color:"#0a2463"}}>Grant Info</h2>
          <Row style={{display:"flex",justifyContent:"center"}} ><Col xs="11" md="8">
          <p style={{textAlign:"start"}}>
          <p style={{textAlign:"start"}}>
          <strong>AS HEALTHY AS POSSIBLE</strong>
        </p>
        <p style={{textAlign:"start"}}>Founded 2023</p>
          1959 N Peacehaven Rd. PMB 235
          Winston-Salem, NC 27106-4850
          </p>
          <p style={{textAlign:"start"}}>E-mail Address: info@AsHealthyAsPossible.net</p>
          <p style={{textAlign:"start"}}>Web Site Address: AsHealthyAsPossible.net </p>
          <p style={{textAlign:"start"}}>
          <p style={{textAlign:"start"}}></p><strong>PURPOSE:</strong> In pursuit of our mission; to have a positive impact on the lives of those with a POLR2A mutation and to
          help them, their families, and their caregivers stay as healthy as possible; As Healthy As Possible is accepting applications for receipt of an annual grant of $1000.</p>
          <p style={{textAlign:"start"}}><strong>NAME OF PROGRAM:</strong> As Healthy As Possible Annual Grant</p>
          <p style={{textAlign:"start"}}><strong>TYPE:</strong> Annual grant support for a variety of activities in the areas of education and medical research. Awards are
          not available for endowment, contingency or reserve purposes.</p>
          <p style={{textAlign:"start"}}><strong>YEAR PROGRAM STARTED:</strong> 2024<br/></p>
          <p style={{textAlign:"start"}}><strong>AREAS OF INTEREST:</strong> Primarily studies, publications, or scientific work related to POLR2A and associated genetic processes and
          mechanisms of function and interaction; but including multiple aspects of the transcription process, RNA, DNA-
          /RNA- binding proteins, and/or other polymerases.</p>
          <p style={{textAlign:"start"}}><strong>LEGAL BASIS:</strong> Private foundation; tax exempt under statute 501(c)(3) of the Internal Revenue Code</p>
          <p style={{textAlign:"start"}}><strong>ELIGIBILITY:</strong> Individual students or researchers worldwide, as well as U.S. tax-exempt nonprofit organizations with
          appropriate interests are eligible to apply. Grants are not made, however, to organizations which distribute them to
          beneficiaries of their own selection. Priority is given to projects most closely related or expected to have direct
          impact on individuals with a POLR2A mutation and projects which are not normally financed by public tax funds.</p>
          <p style={{textAlign:"start"}}><strong>GEOGRAPHIC RESTRICTIONS:</strong> Worldwide individuals, U.S. tax-exempt nonprofit organizations.</p>
          <p style={{textAlign:"start"}}><strong>FINANCIAL DATA:</strong> Single award of $1000.</p>
          <p style={{textAlign:"start"}}><strong>AMOUNT OF SUPPORT PER AWARD:</strong> $1000</p>
          <p style={{textAlign:"start"}}><strong>TOTAL AMOUNT OF SUPPORT:</strong> $1000</p>
          <p style={{textAlign:"start"}}><strong>APPLICATION INFORMATION:</strong><br/> No official application forms are issued. <br/>Interested applicants should submit an
          informal proposal which includes: <br/>(1) an explanation of the purpose of proposed study and manner for which the
          grant would be used; <br/>(2) aid sought and amounts received from other foundations and sources (include names)
          during the preceeding three years; <br/>(3) aid presently being sought from other sources (or whether such solicitation is
          contemplated) and, if so, from whom; <br/>(4*) copy of tax-exempt letter from the U.S. Treasury and Section 509
          classification determination; <br/>(5*) latest auditied balance sheet and detailed income account and; <br/>(6*) signature and
          approval of the overall head of the applicant institution or organization; <br/>(7) three copies of application proposals
          and covering letters should be submitted. Letters of support from appropriate authorities and/or organizations are
          also encouraged.<br/> *in lieu of items (4)-(6) individual applicants may submit appropriate confirmation of identity and an explanation of
          necessity of award.</p>
          <p style={{textAlign:"start"}}><strong>DURATION:</strong> The award is a single, one-time award of $1000 made in August of each year.</p>
          <p style={{textAlign:"start"}}><strong>DEADLINE:</strong> Complete proposals and supporting documents must be received by May 3rd of the year requested.</p>
          <p style={{textAlign:"start"}}><strong> IRS IDENTIFICATION NUMBER:</strong> 92-1715452</p>
          <p style={{textAlign:"start"}}><strong>EXECUTIVE BOARD:</strong><br/>
          Daniel J. O’Donnell<br/>
          Johanna T. O’Donnell<br/>
          Trine Hestbaek<br/>
          Michael Wells<br/>
          <br/>
          <strong>MEDICAL ADVISORY BOARD:</strong>
          <br/>Christine Erdie-Lalena
          <br/>Marrissa Vawter-Lee
          <br/>James L. O’Donnell<br/>
          <br/>
          <strong>ADDRESS INQUIRIES TO:</strong> As Healthy As Possible Annual Grant
          1959 N Peacehaven Rd. PMB #235
          Winston-Salem, NC 27106-4850
          (31)<br/> ** PLEASE NOTE:
          If an applicant is unclear as to the Foundation’s current fields of interest, an inquiry directed to it describing the
          applicant’s project may save the trouble or expense of preparing and submitting a formal application.</p>
          </Col>
          </Row>
        </div>
       
      </div>
      </div>
  );
}