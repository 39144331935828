import React, { useContext, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext, storage } from "../utils";

export default function Footer(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx && userCtx.currentUser ? userCtx.currentUser : null;
  let appVersion = "";
  try {
    appVersion = storage.getItem("app-version");
  } catch {}

  return (
    <div className={`${currentUser ? "footerAuth" : ""} footer text-white mr-0 pr-0`}>
      
    </div>
  );
}
