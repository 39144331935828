import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import { UserContext, storage, constants, helpers } from "../utils";
import CustomNav from "./navbar/CustomNav";

export default function HowToHelp(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const heightOutput = document.querySelector("#height");
  const widthOutput = document.querySelector("#width");

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div style={{ justifyContent: "center", textAlign: "center" }}>
      <CustomNav />
      <h1>How To Help</h1>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          height: "-webkit-fill-available",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <h2>Donate</h2>
      </div>

      <Container></Container>
      <Row
        style={{ width: "100%", margin: "0", backgroundColor: "white" }}
      ></Row>
    </div>
  );
}
